import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../components/RawHTML';
import Container from '../../../../../components/Container';

const html = `
 <html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">AnnotationToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[AnnotationToolbar rotateToOrientation:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)rotateToOrientation:(UIInterfaceOrientation)orientation</td></tr>
<tr><th>To</th><td class="declaration">- (void)rotateToOrientation:(UIDeviceOrientation)orientation</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">AnnotationViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> AnnotationViewController.alsoBookmarks</div>
<div class="difference"><span class="status removed">Removed</span> AnnotationViewController.alsoOutline</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">AnnotEditTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> AnnotEditTool.maintainAspectRatio</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">BookmarkViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> BookmarkViewController.alsoAnnotations</div>
<div class="difference"><span class="status removed">Removed</span> BookmarkViewController.alsoOutline</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">ColorDefaults.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> #def ANNOT_LINE</div>
<div class="difference"><span class="status added">Added</span> #def ANNOT_SQUARE</div>
<div class="difference"><span class="status added">Added</span> #def ANNOT_CIRCLE</div>
<div class="difference"><span class="status added">Added</span> #def ANNOT_POLYGON</div>
<div class="difference"><span class="status added">Added</span> #def ANNOT_POLYLINE</div>
<div class="difference"><span class="status added">Added</span> #def ANNOT_INK</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">DigitalSignatureTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> DigitalSignatureTool.showDefaultSignature</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">FloatingSigViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> FloatingSigViewController.showDefaultSignature</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">OutlineViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> OutlineViewController.alsoAnnotations</div>
<div class="difference"><span class="status removed">Removed</span> OutlineViewController.alsoBookmarks</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PanTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PanTool.showMenuOnTap</div>
<div class="difference"><span class="status added">Added</span> PanTool.showMenuNextTap</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> e_high_quality</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTDocumentOpening.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentOpening</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentOpening openDocumentWithURL:error:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewControllerHideControlsInterval</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewControllerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewControllerDelegate documentViewController:willShowToolbar:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewControllerDelegate documentViewController:didShowToolbar:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewControllerDelegate documentViewController:willHideToolbar:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewControllerDelegate documentViewController:didHideToolbar:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewControllerDelegate documentViewControllerDidHideNavigationBar:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewControllerDelegate documentViewControllerDidShowNavigationBar:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController setDocument:error:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController setDocumentWrapper:error:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.document</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.documentWrapper</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController saveDocument:completionHandler:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController closeDocument]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.delegate</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.nightModeEnabled</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.controlsHidden</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController setControlsHidden:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.thumbnailSliderHidden</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController setThumbnailSliderHidden:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.automaticallyHidesControls</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.automaticControlHidingDelay</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController restartAutomaticControlHidingTimer]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController restartAutomaticControlHidingTimerWithDelay:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController stopHideControlsTimer]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTDocumentWrapper.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentWrapper</div>
<div class="difference"><span class="status added">Added</span> PTDocumentWrapper.pdfDoc</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTNavigationListsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTNavigationListsViewController</div>
<div class="difference"><span class="status added">Added</span> PTNavigationListsViewController.listViewControllers</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTPDFTronUtil.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTPDFTronUtil</div>
<div class="difference"><span class="status added">Added</span> +[PTPDFTronUtil PTPDFDocFromImage:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTTabbedDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewControllerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewControllerDelegate tabbedDocumentViewController:willRemoveTabAtIndex:]</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController.tabsEnabled</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController.maximumTabCount</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController.tabURLs</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController addTabWithURL:selected:error:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController insertTabWithURL:atIndex:selected:error:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController removeTabWithURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController removeTabAtIndex:]</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController.selectedURL</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController.selectedIndex</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController.tabBarHidden</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController setTabBarHidden:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController.delegate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">SelectionRectContainerView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> SelectionRectContainerView.displaysOnlyCornerResizeHandles</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">ToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> ToolManager.showMenuOnTap</div>
<div class="difference"><span class="status added">Added</span> ToolManager.showDefaultSignature</div>
</div>

</div>
</body>
</html>

`


export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}